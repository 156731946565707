import React, { useState, useEffect } from 'react';
import './Buy.css';
import { IoAlertOutline } from "react-icons/io5";

import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';
import {config} from '../../config'
import GoogleButton from './GoogleAuth';
import LeftPanel from './leftPanel';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Buy = ({ }) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [plans, setPlans] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showPayPalPopup, setShowPayPalPopup] = useState(false);
    const [modalmessage, setModalmessage] = useState('');
    const [showmodal, setShowmodal] = useState(false);    
    const premiumButtonRef = useRef(null);
    const navigate = useNavigate();
    const googleAuthRef = useRef(null);

    useEffect(() => {
        const fetchPaypalPlans = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${config.baseURL}/get_dodo_plans.php`);
                if (!response.ok) throw new Error('Failed to fetch PayPal plans');
                const data = await response.json();
                const transformedPlans = data.items.map(items => {   
                                     
                    return {
                        id: items.product_id,
                        name: items.name,
                        currency: 'USD',
                        amount: items.price,
                        interval: items.name === 'Weekly' ? '1' : 
                                  (items.name === 'Monthly' ? '2' : '2'),
                        intervalCount: items.name || 1,
                    };
                });
                setPlans(transformedPlans);
                logEvent(analytics, 'fetch_paypal_plans_FacelessVideo', {
                    
                }); 
                checkSubscription();
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPaypalPlans();
        checkActivePlan();
    }, []);
    
    const handleBackClick = () => {
      navigate(-1);
    };


    useEffect(() => {
      const message = sessionStorage.getItem("premiumError");
     
      if (message) {
          toast.warn(message, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
          setTimeout(() => {
            sessionStorage.removeItem("premiumError");
          }, 10000);
      }
  }, []);

        
    useEffect(() => {
        logEvent(analytics, 'Premium_page_opend_FacelessVideo', {
            component_name: 'Buy'
        });
        
    }, []);

    const checkSubscription = async () => {
        try {
            const response = await fetch(`${config.baseURL}/check_subscription.php`, {
                method: 'GET',
                credentials: 'include',
            });
    
            const data = await response.json(); // Parse the response as JSON
    
            if (data.error) {
                
            } else {
                // Handle the subscription data here
                localStorage.setItem('subscriptionStatus', JSON.stringify(data.subscription_status));
                localStorage.setItem('planId', data.plan_id);
                checkActivePlan();// Save plan_id to localStorage
            }
        } catch (error) {
           
        }
    };

    const handleLoginSuccess = (userProfile) => {
        if (userProfile) {
          ;
            setShowModal(false);           
            localStorage.setItem('showComponent', 'true');
            localStorage.setItem('loginSuccess', 'true');

           
                logEvent(analytics, 'Login Button on buy premium', {
                    button_name: 'login_button_FacelessVideo',
                });
                
                checkAndSubscribeOnLoad();
                
        } else {
           
            
        }
        
    };
    const handleSelectedPlanFromStorage = () => {
        const planId = localStorage.getItem('selectedPlan');
        
        // If a plan ID exists in localStorage, call handleSelect
        if (planId) {
            handleSelect(planId);
        }
    };
    
    // UseEffect to run only after successful login (check loginSuccess flag)
    useEffect(() => {
        // Check if login was successful (i.e., loginSuccess flag exists in localStorage)
        const loginSuccess = localStorage.getItem('loginSuccess');
    
        if (loginSuccess) {
            // If login was successful, handle the selected plan
            handleSelectedPlanFromStorage();
    
            // Remove the login success flag after processing
            localStorage.removeItem('loginSuccess');
        }
    }, []);

    const handleLoginFailure = (error) => {
      
        if (error.error) {
            alert(`Login failed: ${error.error}`);
        } else {
            
        }
    };
    
        const checkActivePlan = () => {
            const storedPlanId = localStorage.getItem('planId');
            const subscriptionStatus = JSON.parse(localStorage.getItem('subscriptionStatus'))?.toUpperCase();

    
            if (storedPlanId && subscriptionStatus === 'ACTIVE') {
                setPlans((prevPlans) =>
                    prevPlans.map((plan) => {
                        const isActive = plan.id === storedPlanId;
                        return isActive
                            ? { ...plan, active: true }
                            : { ...plan, active: false };
                    })
                    
                );
                
            }
        };
    
        


    const handleSelect = (planId) => {
        setSelectedPlan(planId);
        localStorage.setItem('selectedPlan', planId);
        logEvent(analytics, 'plan_selected_FacelessVideo', {
            plan_id: planId
        });
    };

   const handleSubscribe = async (selectedPlanId) => {
       logEvent(analytics, 'Subscribe button clicked landing page', {
         button_name: 'landingPage_FacelessVideo',
     });
       try {
         // Save the selectedPlanId in localStorage before triggering Google login
         localStorage.setItem('selectedPlanIdForReuse', selectedPlanId);
     
         // Step 2: Call the validation API to check if the user is logged in
         const response = await fetch(`${config.baseURL}/validate_token.php`, {
           method: 'GET',
           headers: {
             'Authorization': `Bearer ${getCookie('auth_token')}`,  // Assuming the JWT token is retrieved from the cookies
           },
           credentials: 'include',  // Include cookies if needed for session handling
         });
     
         const data = await response.json();
     
         // Step 3: Handle invalid or expired token
         if (response.status !== 200 || data.message === 'Invalid token' || data.error === "Wrong number of segments") {
           googleAuthRef.current.login();
           return; // Exit the function after triggering Google login
         }
     
         // Step 4: Extract user email from the validation response
         const userEmail = data.user.email;
     
         // Step 5: Build the URL with the user's email and selected plan, then open it in a new tab
         const redirectUrl = `https://checkout.dodopayments.com/buy/${selectedPlanId}?quantity=1&redirect_url=https://aiacademicswriter.com/Dashboard&email=${encodeURIComponent(userEmail)}`;
         window.open(redirectUrl, '_blank');  // Open the URL in a new tab
       } catch (error) {
         console.error('Error while validating token:', error);
         alert('An error occurred. Please try again later.');
         logEvent(analytics, 'Error came while subscribing landing page', {
           button_name: 'landingPage_FacelessVideo',
       }); // Show modal if there is any error during token validation
       }
     };
    
     const checkAndSubscribeOnLoad = () => {
        const selectedPlanId = localStorage.getItem('selectedPlanIdForReuse');
        
        if (selectedPlanId) {
          // If a selected plan ID exists in localStorage, call handleSubscribe
          handleSubscribe(selectedPlanId);
      
          // Remove the selected plan ID from localStorage after it has been used
          localStorage.removeItem('selectedPlanIdForReuse');
        }
      
        // After running the logic, remove the window.onload event to prevent it from running again
        window.onload = null;  // This will prevent the function from being called again
      };
      
      // Call this function when the page is loaded
      checkAndSubscribeOnLoad();
    
    



    const getCookie = (name) => {
        const nameEq = name + "=";
        const ca = document.cookie.split(';');
      
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
        }
        return "";
    };

    const closeModal = () => {
        setShowModal(false);
    };
    
    const closemodal = () => {
        setShowmodal(false);
    };
    
    const closePayPalPopup = () => {
        setShowPayPalPopup(false);
        document.body.style.overflow = '';
        logEvent(analytics, 'paypal_popup_closed_FacelessVideo', {
            user_action: 'cancel'
        });
    };




    return (
    <>
    <LeftPanel/>
     <ToastContainer />
     <div className='seperation-div'>
    <div className='buy_page_full'>
                <button className="back-button-buypage" ref={premiumButtonRef} onClick={handleBackClick}>
                <img className="back-button-buypage" src={config.Backbutton} alt="Back" />
                <div style={{ display: 'none' }}>
  <GoogleButton 
    ref={googleAuthRef} 
    id="logout_header" 
    onLoginSuccess={handleLoginSuccess} 
    onLoginFailure={handleLoginFailure} 
  />
</div>

            </button>
            <div className='subscription_BuyPage' id='Pricing_BuyPage'> 
  <div className='subscription_title_BuyPage'>Pricing Plan</div>
  <div className='subscription_subtitle_BuyPage'>Pick the plan that best fits what you're looking for</div>

  <div className='plans_wrapper_BuyPage'>
    <div className='plans_wrapper'>
      {plans.map((plan, index) => (
        <div className={`plan-${index + 1}`} key={plan.id}>
          <div>
            <div className='price_details'>
              <div className={`price${index + 1}`}>${plan.amount / 100}</div> {/* Assuming 'price' is in cents */}
              <p className={`price_interval${index + 1}`}> /{plan.name === 'Weekly' ? 'Weekly' : plan.name === 'Half Yearly' ? 'Half Yearly' : 'Yearly'}</p>
            </div>
            <div className={`discription${index + 1}`}>
              <div className={`price${index + 1}`}>{plan.name}</div>
              {/* Static description subtitles */}
              {index === 0 && (
                <div className={`discription_subtitle1`}>
                  Unleash the power of automation
                </div>
              )}
              {index === 1 && (
                <div className={`discription_subtitle2`}>
                  Advanced tools to take your work to the next level.
                </div>
              )}
              {index === 2 && (
                <div className={`discription_subtitle3`}>
                  Automation plus enterprise-grade features
                </div>
              )}
            </div>
    
            {/* Points List - Common for All Plans */}
            
            <div className='points_list_full'>
              {[
                { text: 'AI Advanced Academic Writing Tool', feature: 'textToVideo' },
                { text: 'Unlimited Access to Academic Writing & Research', feature: 'hdExports' },
                { text: 'Extensive Research Database', feature: 'noWatermarks' },
                { text: 'Ads Free', feature: 'adsFree' },
                
              ].map((point, pointIndex) => {
                // Logic for determining if the feature should be unlocked or locked based on the plan
                let isFeatureAvailable = true;
    
                if (plan.name === 'Weekly') {
                  // Weekly plan: Has basic features only, so all other features are locked
                  if (point.feature === 'bgMusic' || point.feature === 'stockFootage' || point.feature === 'hdResolution') {
                    isFeatureAvailable = false;
                  }
                } else if (plan.name === 'Half Yearly') {
                  // Half Yearly plan: Unlocks some additional features
                  if (point.feature === 'hdResolution') {
                    isFeatureAvailable = false;
                  }
                } else if (plan.name === 'Yearly') {
                  // Yearly plan: All features are unlocked
                  isFeatureAvailable = true;
                }
    
                return (
                  <div className={`ponits_list${index + 1}`} key={pointIndex}>
                    <img 
                      src={isFeatureAvailable ? config.check_point : config.check_point_locked_white} 
                      alt="" 
                      className={`colored_check${index + 1}`} 
                    />
                    <img 
                      src={isFeatureAvailable ? config.check_point_white : config.check_point_locked_white} 
                      alt="" 
                      className={`white_check${index + 1}`} 
                    />
                    {point.text}
                  </div>
                );
              })}
            </div>
          </div>
          <button className={`choose_plan${index + 1}`} onClick={() => handleSubscribe(plan.id)}>Choose plan</button>
        </div>
      ))}
    </div>
  </div>
</div>
</div>
         
</div>
         </>
    );
};

export default Buy;
