import {React,useState,useRef} from 'react';
import writeLogo from '../../assets/write logo.svg'; 
import writeLogoActive from '../../assets/write logo - Copy.svg';
import historyLogo from '../../assets/history_logo.svg'; 
import historyLogoActive from '../../assets/history_logo - Copy.svg'; 
import './createacademic.css';
import LeftPanel from './leftPanel'; 
import { logEvent } from 'firebase/analytics';
import {config} from '../../config';
import { analytics } from './firebase'; 
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FullScreenLoader from './FullScreenLoader'
const Createacademic = ({ }) => {
    const [selectType, setselectType] = useState('');
    const [SupportiveInput, setSupportiveInput] = useState('');
    const [SupportiveInput2, setSupportiveInput2] = useState('');
    const [language, setLanguage] = useState('');
    const [level, setLevel] = useState('Short');
    const [selectedButton, setSelectedButton] = useState('button1');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userProfile')));
    const [errors, setErrors] = useState({}); // Error state for validation
    const subscriptionStatus = JSON.parse(localStorage.getItem('subscriptionStatus'));
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setPopupVisible] = useState(false);
    const googleanalytics  = process.env.REACT_APP_GOOGLE_ANALYITCS_ID;
    const textAreaRef = useRef(null);
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const navigate = useNavigate();




    const location = useLocation();

    const initialText = location.state?.text || '';  // Prefill text
    const info = location.state?.info || {};  
    const receivedKey = location.state?.key || 'Academic';
    const [Topic, setTopic] = useState(initialText);
    const [Keypoints, setKeypoints] = useState('');


    const handleButtonClick = (selectedLevel) => {
    setLevel(selectedLevel);
  };
  

  const handleBackClick = () => {
    navigate(-1);
  };


  const handleOutlineClick = (buttonId) => {
    setSelectedButton(buttonId);
    
  };


  
  const getButtonImage = (buttonId) => {
    switch (buttonId) {
      case 'button1':
        return config.Outline_1;  // Replace with actual image URL
      case 'button2':
        return config.Outline_2;  // Replace with actual image URL
      case 'button3':
        return config.Outline_3;  // Replace with actual image URL
      default:
        return config.Outline_1;
    }
  }
  
  // Function to return upper text based on buttonId
  const getUpperText = (buttonId) => {
    switch (buttonId) {
      case 'button1':
        return 'No Outline bigger in size';
      case 'button2':
        return 'Standard headings';
      case 'button3':
        return 'Creative headings';
      default:
        return '';
    }
  }
  
  // Function to return lower text based on buttonId
  const getLowerText = (buttonId) => {
    switch (buttonId) {
      case 'button1':
        return 'Start with a blank document';
      case 'button2':
        return 'Add standard headings (Introduction, Methods, Results etc.)';
      case 'button3':
        return 'AI will generate headings based on your document prompt';
      default:
        return '';
    }
  }
  
       
  const handleGenerate = async () => {
    setErrors({});
    let hasError = false;
    logEvent(analytics, 'Generated_academic', {
        button_name: 'Generate_button_academic',
    });

    // Validation checks
    if (!Topic) {
        setErrors(prev => ({ ...prev, Topic: 'Topic is required.' }));
        hasError = true;
        textAreaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.Topic;
                return updatedErrors;
            });
        }, 5000);
    }

    if (hasError) return; // Exit if there are validation errors

    setIsLoading(true);
    setIsButtonVisible(false);
    document.body.classList.add('no-scroll');

    try {
        let currentClickCount = 0;
        let maxClicks = 5; // Default for non-pro users
        let isPro = false;

        // ✅ Fetch click count from backend using credentials
        const response = await fetch(`${config.baseURL}/click_count.php`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        });

        const clickData = await response.json();
        if (clickData) {
            if (clickData.error) {
                alert(clickData.error);
                return;
            }
            currentClickCount = clickData.click_count;
            isPro = clickData.is_pro === 'yes';
            maxClicks = isPro ? 25 : 5;
        }

        // Check click limits
        currentClickCount = Math.floor(currentClickCount);
        maxClicks = Math.floor(maxClicks);

        if (currentClickCount >= maxClicks) {
            
          sessionStorage.setItem("premiumError", "Your Daily Limit Reached. Upgrade to Pro for unlimited access!");
          navigate('/premium');

            
            return;
        }

        // Proceed with generation
        const requestBody = {
            topic: Topic,
            selectType: selectType,
            supportiveInput: SupportiveInput,
            supportiveInput2: SupportiveInput2,
            selectedLanguage: language,
            id: user ? user.id : null,
            key: receivedKey,
            keypoints: Keypoints,
            level: level,
            outline: selectedButton,
        };

        // Call the document generation API
        const generationResponse = await fetch(`${config.baseURL}/openai_api.php`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        const rawResponse = await generationResponse.text();
        let data;

        try {
            data = JSON.parse(rawResponse);
        } catch (error) {
            alert("Received an invalid response from the server. Please try again.");
            return;
        }

        if (data && data.success && data.resultText) {
            const resultText = data.resultText;
            navigate('/result', { state: { result: resultText } });

            setTopic('');
            setselectType('');
            setSupportiveInput('');
            setSupportiveInput2('');
            setLanguage('');
        } else {
            alert("Error: Unexpected response from the API.");
            setTopic('');
            setselectType('');
            setSupportiveInput('');
            setSupportiveInput2('');
            setLanguage('');
        }
    } catch (error) {
        alert("An error occurred while generating the document.");
        logEvent(analytics, 'error', {
            error_type: 'api_call_failed',
            error_message: 'Failed to generate document due to API error.',
            action: 'generate_button_click',
        });
    } finally {
        setIsLoading(false);
        document.body.classList.remove('no-scroll');
    }
};






    return (
        <div className='create_background'>
           {isLoading && <FullScreenLoader />}
           {isPopupVisible && (
    <div className="popup-notification">
        <p>{popupMessage}</p>
    </div>
)}

       <LeftPanel/>
       <div className='create_main'>
          
          <div className='create_title'> Create {receivedKey}<img src={config.crossimg} alt="" onClick={handleBackClick}/></div>
        
          <div className='create_inputbox1'>Topic / Description 
            <textarea
             className={`create_input_1 ${errors.Topic ? 'error' : ''}`}
             ref={textAreaRef}
              name="Topic" id=""
                placeholder='Describe specific instruction for this'
                value={Topic}
                onChange={(e) => setTopic(e.target.value)}
                maxLength={1000}>  </textarea>
                 {errors.Topic && <div className="error-message">{errors.Topic}</div>} {/* Error message */}
           <div className='notice_topic'><b>Note</b>: Provide additional context to improve the quality of the generated output.</div></div>



           {(receivedKey === 'Proposal' || receivedKey === 'Thesis') && (
           <div className='create_inputbox1'>Keypoints 
            <textarea
             className={'create_input_1'}
             ref={textAreaRef}
              name="Keypoints" id=""
                placeholder='Describe specific instruction for this'
                value={Keypoints}
                onChange={(e) => setKeypoints(e.target.value)}
                maxLength={1000}>  </textarea>
                 {/* Error message */}
           {/* <div className='notice_topic'><b>Note</b>: Provide additional context to improve the quality of the generated output.</div> */}</div>
            )}

{receivedKey === 'Academic' && (
       <div className="academic-level-container">
      <label className="academic-level-label">Academic Level</label>
      <div className="academic-level-button-container">
        {['Short', 'Medium', 'Relaxed'].map((buttonLevel) => (
          <button
            key={buttonLevel}
            className={`academic-level-button ${level === buttonLevel ? 'selected' : ''}`}
            onClick={() => handleButtonClick(buttonLevel)}
          >
            {buttonLevel}
          </button>
        ))}
      </div>
      {/* Passing the selected value to form */}
      <input type="hidden" name="academicLevel" value={level} />
      
    </div>
    )}
    {receivedKey === 'Academic' && (
    <div className="app_outline">
      <div className="generate-outline">
        <label className="generate-outline_label">Generate Outline</label>

        <div className="buttons-container">
          {['button1', 'button2', 'button3'].map((buttonId) => (
            <button
              key={getUpperText(buttonId)}
              className={`button-outline ${selectedButton === buttonId ? 'selected' : ''}`}
              onClick={() => handleOutlineClick(buttonId)}
            >
              <div className={`circle ${selectedButton === buttonId ? 'selected-circle' : ''}`}></div>

              {/* Image/Icon */}
              <img 
                className="button-icon" 
                src={getButtonImage(buttonId)} 
                alt={buttonId} 
              />
              
              <div className="button-text">
                {/* Upper Line (Bold) */}
                <span className="button-text-upper">
                  {getUpperText(buttonId)}
                </span>
                
                {/* Lower Line (Thin) */}
                <span className="button-text-lower">
                  {getLowerText(buttonId)}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
    )}

{(receivedKey === 'Academic' || receivedKey === 'Thesis') && (
                                                  <div>
                                                <div className="topic">Select {receivedKey} Type</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="type"
                                                    id="language"
                                                    value={selectType}
                                                    onChange={(e) => setselectType(e.target.value)}
                                                >
                                                   <option value="No Type">No Type</option>
                                                    <option value="Argumentative">Argumentative</option>
                                                    <option value="Analytical">Analytical</option>
                                                    <option value="Expository">Expository</option>
                                                    <option value="Narrative">Narrative</option>
                                                    <option value="Persuasive">Persuasive</option>
                                                    <option value="Comparing/Contrast">Comparing/Contrast</option>
                                                    <option value="Classic">Classic</option>
                                                    <option value="Critique">Critique</option>
                                                    <option value="Memoir">Memoir</option>
                                                    

                                                </select>                                                                   
                                               
                                            </div>
                                             )}

{(receivedKey === 'Academic' || receivedKey === 'Proposal') && (

                                            <div>
                                                <div className="topic">{config.SupportiveInput}</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="length"
                                                    id="language"
                                                    value={SupportiveInput}
                                                    onChange={(e) => setSupportiveInput(e.target.value)}
                                                >
                                                    <option value="No Citation">No Citation</option>
                                                    <option value="APA">APA</option>
                                                    <option value="MLA">MLA</option>
                                                    <option value="CMS or Chicago Style">CMS or Chicago Style</option>
                                                    <option value="IEEE">IEEE</option>
                                                    <option value="Harvard Style">Harvard Style</option>
                                                    <option value="AMA">AMA</option>
                                                    <option value="Vancouver">Vancouver</option>
                                                    <option value="Turabian">Turabian</option>
                                                    <option value="No Style">No Style</option>
                                                    
                                            
                                                </select>                                                                   
                                            
                                            </div>)}
                                            {receivedKey === 'Academic' && (
                                            <div>
                                                <div className="topic">{config.SupportiveInput2}</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="length"
                                                    id="language"
                                                    value={SupportiveInput}
                                                    onChange={(e) => setSupportiveInput2(e.target.value)}
                                                >
                                                    <option value="Formal">Formal</option>
                                                    <option value="Informal">Informal</option>
                                                    <option value="Friendly">Friendly</option>
                                                    <option value="Funny">Funny</option>
                                                    <option value="Convincing">Convincing</option>
                                                    <option value="Luxury">Luxury</option>
                                                    <option value="Relaxed">Relaxed</option>
                                                    <option value="Professional">Professional</option>
                                                    <option value="Witty">Witty</option>
                                                    <option value="Adventurous">Adventurous</option>
                                                    <option value="Persuasive">Persuasive</option>
                                                    <option value="Bold">Bold</option>
                                                    <option value="Empathetic">Empathetic</option>
                                                   
                                            
                                                </select>                                                                   
                                            
                                            </div>)}


                                            <div>
                                                <div className="topic">Select Language</div>
                                                <select
                                                    className="input_3"  // Light red border for error
                                                    name="language"
                                                    id="language"
                                                    value={language}
                                                    onChange={(e) => setLanguage(e.target.value)}
                                                >

                                                    <option value="English">English</option>
                                                    <option value="Arabic">Arabic (RTL)</option>
                                                    <option value="Bulgarian">Bulgarian</option>
                                                    <option value="Chinese">Chinese (Simplified)</option>
                                                    <option value="French">French</option>
                                                    <option value="German">German</option>
                                                    <option value="Hebrew">Hebrew (RTL)</option>
                                                    <option value="Hindi">Hindi</option>
                                                    <option value="Indonesian">Indonesian</option>
                                                    <option value="Italian">Italian</option>
                                                    <option value="Japanese">Japanese</option>
                                                    <option value="Portuguese">Portuguese</option>
                                                    <option value="Punjabi">Punjabi</option>
                                                    <option value="Russian">Russian</option>
                                                    <option value="Spanish">Spanish</option>
                                                    <option value="Swahili">Swahili</option>
                                                    <option value="Tagalog">Tagalog</option>
                                                    <option value="Urdu">Urdu</option>
                                                    
                                                </select>                            
                                            </div>

                                            <img
                                            className='generate_btn'
                                            src={config.generate}
                                            alt="Generate"
                                            onClick={handleGenerate}
                                        />
    </div>


   
        </div>
    );
};

export default Createacademic;
