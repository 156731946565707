import {React,useState,useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import  GoogleLogin  from './GoogleAuth';
import writeLogo from '../../assets/write logo.svg'; 
import writeLogoActive from '../../assets/write logo - Copy.svg';
import historyLogo from '../../assets/history_logo.svg'; 
import historyLogoActive from '../../assets/history_logo - Copy.svg'; 
import './leftpanel.css';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';
import {config} from '../../config';

const Leftpanel = ({ }) => {
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('Home');
    const googleAuthRef = useRef(null);
       
    const handleNavigation = (path, buttonName) => {
        setActiveButton(buttonName);
        navigate(path);
    };
    return (
        <div className='background_leftPanel'>
            <div className='top_leftPanel'>
                        <div className='icon_title_leftpanel'>
                        <img src={config.icon_leftPanel} alt="" className='icon_leftpanel'/>
                        <div>Ai Academic Generator</div>
                        </div>

                        <a  href="/premium" className='buy_leftPanel'><img src={config.crown} alt="" />Buy Premium</a>

                        <div className='buttons_leftPanel'>
                        <button
                className={`nav_buttons_leftpanle ${activeButton === 'Home' ? 'active' : ''}`}
                onClick={() => handleNavigation('/', 'Home')}
            >
                <img src={config.home} alt="" /> Home
            </button>
            <button
                className={`nav_buttons_leftpanle ${activeButton === 'History' ? 'active' : ''}`}
                onClick={() => handleNavigation('/history', 'History')}
            >
                <img className='scroll-img' src={config.history} alt="" /> History
            </button>
            <button
                className={`nav_buttons_leftpanle ${activeButton === 'Privacy' ? 'active' : ''}`}
                onClick={() => handleNavigation('/privacy', 'Privacy')}
            >
                <img className='scroll-img' src={config.privacy} alt="" /> Privacy Policy
            </button>
            <button
                className={`nav_buttons_leftpanle ${activeButton === 'Refund' ? 'active' : ''}`}
                onClick={() => handleNavigation('/refundpolicy', 'Refund')}
            >
                <img className='scroll-img' src={config.refund} alt="" /> Refund Policy
            </button>
            <button
                className={`nav_buttons_leftpanle ${activeButton === 'Terms' ? 'active' : ''}`}
                onClick={() => handleNavigation('/terms&condition', 'Terms')}
            >
                <img className='scroll-img' src={config.terms} alt="" /> Terms & Conditions
            </button>
            {/* <button
                className={`nav_buttons_leftpanle ${activeButton === 'Blog' ? 'active' : ''}`}
                onClick={() => handleNavigation('/blog', 'Blog')}
            >
                <img className='scroll-img' src={config.blogs} alt="" /> Blog
            </button> */}

                        </div>
            </div>
            <GoogleLogin ref={googleAuthRef}  id='login_leftPanel' showDropdown={true}/>
            {/* <GoogleButton id="logout_header" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} /> */}
        </div>
    );
};

export default Leftpanel;
