import React, { useState,useEffect, useRef }  from 'react';
import './LandingPage.css'; // Add your custom CSS styles
import {config} from '../../config'
import { Link } from 'react-router-dom';
import GoogleButton from './GoogleAuth';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics'; 
import ReactGA from 'react-ga4';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const heroImageJpg = require('../../assets/heroimagepng.png')
const heroImageSvg = require('../../assets/heroimagesvg.svg').default;
const heroImagewebp = require('../../assets/heroimagewebp.webp').default;
const LandingPage = () => {
  const currentYear = new Date().getFullYear();
  const googleanalytics  = process.env.REACT_APP_GOOGLE_ANALYITCS_ID;
  const googleAuthRef = useRef(null);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    ReactGA.initialize(googleanalytics); // Replace with your tracking ID
    ReactGA.send('pageview'); // Track initial page view
}, []);
useEffect(() => {
    ReactGA.initialize(googleanalytics); 
    ReactGA.send('pageview'); 
    
  
    ReactGA.event({
      category: 'Faceless_video', 
      action: 'pageview_FacelessVideo_landingPage', 
      label: 'Page View for Faceless Video Generator', 
    });
  }, []);
useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'landingpage_opend_FacelessVideo');
    } else {
     
    }
  }, []);

  const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
};


    useEffect(() => {
        preloadImage(heroImageJpg);
        preloadImage(heroImageSvg);
        preloadImage(heroImagewebp);
    }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    logEvent(analytics, 'Faq opend landing page', {
                        button_name: 'landingpage_FacelessVideo',
                    });
    setActiveIndex(activeIndex === index ? null : index); 
  };
  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    const heroImage = document.querySelector('.heroimage');
    const rect = heroImage.getBoundingClientRect();
    
    // Check if the image is in the viewport (i.e., the top is visible)
    if (rect.top < window.innerHeight && rect.bottom >= 0) {
      setIsVisible(true); // Image is in viewport, trigger animation
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', checkVisibility);

    // Run checkVisibility once on component mount
    checkVisibility();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, []); 


  const [isOpen, setIsOpen] = useState(false);
  
  

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    // Function to check if click is outside
    const handleClickOutside = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) && 
        dropdownRef.current && !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });  // Smooth scrolling effect
  };
  

const handleLoginFailure = (error) => {
  
    if (error.error) {
        alert(`Login failed: ${error.error}`);
    } else {
        
    }
};


const [plans, setPlans] = useState([]);


useEffect(() => {
        const fetchDodoPlans = async () => {
            
            try {
                const response = await fetch(`${config.baseURL}/get_dodo_plans.php`);
                if (!response.ok) throw new Error('Failed to fetch PayPal plans');
                const data = await response.json();
                const transformedPlans = data.items.map(items => {   
                                     
                    return {
                        id: items.product_id,
                        name: items.name,
                        currency: 'USD',
                        amount: items.price,
                        interval: items.name === 'Weekly' ? '1' : 
                                  (items.name === 'Monthly' ? '2' : '2'),
                        intervalCount: items.name || 1,
                    };
                });
                setPlans(transformedPlans);
                logEvent(analytics, 'fetch_paypal_plans_FacelessVideo', {
                    
                }); 
                
            } catch (err) {
               
            } finally {
                
            }
        };

        fetchDodoPlans();
        
    }, []);

    const getCookie = (name) => {
      const nameEq = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
      }
      return "";
    };





  const handleSubscribe = async (selectedPlanId) => {
    logEvent(analytics, 'Subscribe button clicked landing page', {
      button_name: 'landingPage_FacelessVideo',
  });
    try {
      // Save the selectedPlanId in localStorage before triggering Google login
      localStorage.setItem('selectedPlanIdForReuse', selectedPlanId);
  
      // Step 2: Call the validation API to check if the user is logged in
      const response = await fetch(`${config.baseURL}/validate_token.php`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getCookie('auth_token')}`,  // Assuming the JWT token is retrieved from the cookies
        },
        credentials: 'include',  // Include cookies if needed for session handling
      });
  
      const data = await response.json();
  
      // Step 3: Handle invalid or expired token
      if (response.status !== 200 || data.message === 'Invalid token' || data.error === "Wrong number of segments") {
        googleAuthRef.current.login();
        return; // Exit the function after triggering Google login
      }
  
      // Step 4: Extract user email from the validation response
      const userEmail = data.user.email;
  
      // Step 5: Build the URL with the user's email and selected plan, then open it in a new tab
      const redirectUrl = `https://checkout.dodopayments.com/buy/${selectedPlanId}?quantity=1&redirect_url=https://aiacademicswriter.com/Dashboard&email=${encodeURIComponent(userEmail)}`;
      window.open(redirectUrl, '_blank');  // Open the URL in a new tab
    } catch (error) {
      console.error('Error while validating token:', error);
      alert('An error occurred. Please try again later.');
      logEvent(analytics, 'Error came while subscribing landing page', {
        button_name: 'landingPage_FacelessVideo',
    }); // Show modal if there is any error during token validation
    }
  };
  


  const checkAndSubscribeOnLoad = () => {
    const selectedPlanId = localStorage.getItem('selectedPlanIdForReuse');
    
    if (selectedPlanId) {
      // If a selected plan ID exists in localStorage, call handleSubscribe
      handleSubscribe(selectedPlanId);
  
      // Remove the selected plan ID from localStorage after it has been used
      localStorage.removeItem('selectedPlanIdForReuse');
    }
  
    // After running the logic, remove the window.onload event to prevent it from running again
    window.onload = null;  // This will prevent the function from being called again
  };
  
  // Call this function when the page is loaded
  checkAndSubscribeOnLoad();
  


  const [user, setUser] = useState(null);

  // Check for user session immediately on page load
  useEffect(() => {
    const checkUserSession = async () => {
      const jwtToken = getCookie('auth_token');
      if (jwtToken) {
        // Try to validate the token with the backend (you may already have a utility function for this)
        const response = await fetch('/validate_token.php', {
          headers: { 'Authorization': `Bearer ${jwtToken}` },
        });
        const data = await response.json();
        
        if (data.user) {
          setUser(data.user); // Successfully authenticated
        } else {
          setUser(null);  // Invalid token
        }
      }
    };

    checkUserSession();
  }, []);

  const handleLoginSuccess = (userData) => {
    setUser(userData);
    checkAndSubscribeOnLoad();
     // Update the state with logged-in user data
  };

  const handleLogout = () => {
    // Remove the token and clear session
    document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    setUser(null);  // Clear the state
  };



  const navigate = useNavigate();  // Hook to navigate programmatically

  const handleVisitDashboard = async () => {
    const jwtToken = getCookie('auth_token'); 
    localStorage.setItem('navigateToDashboard', 'true'); // Get the token from cookies
    
    if (!jwtToken) {
        
        // Automatically trigger the Google login button if JWT is missing
        googleAuthRef.current?.login();  // Trigger the login function
        return;
    }

    try {
        const response = await fetch(`${config.baseURL}/validate_token.php`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
            credentials: 'include',
        });

        const data = await response.json();
        
        if (response.ok && data.user) {
            navigate('/Dashboard');  // Redirect to the dashboard page
        } else {
            removeCookie('auth_token');
            toast.error('Session expired. Please log in again.');
            navigate('/');  // Redirect to login page
        }
    } catch (error) {
        removeCookie('auth_token');
        toast.error('Failed to validate token. Please try again.');
        navigate('/');  // Redirect to login page
    }
};


 

  // Utility function to remove cookies
  const removeCookie = (name) => {
      document.cookie = `${name}=; Max-Age=0; path=/`;
  };



      
  return (
    <div className="landing-page">
     <header className="header-landing-page">
      {/* Left side - Title with Image and Text */}
      <div className="header-left-landing-page">
       { /*<img src={config.logolanding} alt="Logo" className="logo-landing-page" />*/}
        <h1 className="title-landing-page">AI Academic Generator</h1>
      </div>

      {/* Middle - Buttons */}
      <div className="header-middle-landing-page">
      <Link > <button className="header-btn-landing-page" onClick={() => handleVisitDashboard()}>Dashboard</button></Link>
        <button className="header-btn-landing-page" onClick={() => scrollToSection('Footer')}>About Us</button>
        <button className="header-btn-landing-page" onClick={() => scrollToSection('Footer')}>Contact</button>
        <Link to='/blog' style={{ textDecoration: 'none', color: 'inherit' }}><button className="header-btn-landing-page" >Blog</button></Link>
      </div>

      {/* Right side - Login Button */}
      <div className="header-right-landing-page">
        
        <GoogleButton id="login-btn-landing-page" ref={googleAuthRef} onLoginSuccess={handleLoginSuccess}   onClick={handleLogout}  />
      </div>

      
      <div id="hamburger-menu_hero" className="_hero" ref={menuRef}>
      <div className="hamburger-icon_hero" onClick={toggleDropdown}>
        &#9776; {/* Hamburger icon (three horizontal bars) */}
      </div>
      {isOpen && (
        <div className="dropdown-content_hero" ref={dropdownRef}>
         <a onClick={() => handleVisitDashboard()} > Dashboard</a>
          <a href="/blog">Blog</a> 
         <a   onClick={() => { scrollToSection('Footer'); toggleDropdown(); }}>About Us</a>
         <a  onClick={() => { scrollToSection('Footer'); toggleDropdown(); }}>Contact</a>       
          <a ><GoogleButton  id='login_dropdown_hero'   onLoginSuccess={handleLoginSuccess}   onClick={handleLogout}/></a>
          
        </div>
      )}
    </div>

    </header>
    <div className='Hero_landing-page'>
      <div  className='Hero_landing-text_box' >
      <h1 className='Hero_title'>AI Academic Writer Your<br/> Last Minute Academic <br />Writing Companion!</h1>
      <button onClick={() => handleVisitDashboard()} style={{ textDecoration: 'none', color: 'white' }} className='view-dashboard' >Visit Dashboard</button>
      <h2 className='Hero-subtitle-pc'>Struggling with academic writing?<br /> <b>AI Academic Writer</b> makes it effortless! Whether you're working on essays, research papers, assignments, or citations, our AI-powered tool helps you generate well-structured, plagiarism-free, and high-quality content in minutes.</h2>
      </div>



 
    
        <img
      srcSet={`${heroImagewebp} 600w,${heroImageJpg} 1000w, ${heroImageSvg} 1200w`}
      sizes="(max-width: 900px) 100vw, 1200px"
      alt="Hero"
      fetchpriority="high"
      className={`heroimage ${isVisible ? 'visible' : ''}`}
    />
      {/* <img
      src={config.Heroimage}
      alt="Hero"
      className={`heroimage ${isVisible ? 'visible' : ''}`} // Add 'visible' class when image is in viewport
    /> */}
       </div>
        <h2 className='Hero-subtitle-mobile'>Struggling with academic writing?<br /> <b>AI Academic Writer</b> makes it effortless! Whether you're working on essays, research papers, assignments, or citations, our AI-powered tool helps you generate well-structured, plagiarism-free, and high-quality content in minutes.</h2>
        {/* <Link to='/Dashboard' style={{ textDecoration: 'none', color: 'white' }} onClick={handleVisitDashboard} className='view-dashboard'>Visit Dashboard</Link> */}
        

        {/* <img src={config.Heroimage} alt="Image" className='heroimage' /> */}
        
       
    
   


   {/*  <div className='hero2ndpart_container'>
      <div  className='hero2ndpart_left' >
          <div className='hero2ndpart_left_title'>Different Types of Categories we offer</div>
          <div className='hero2ndpart_left_sub_heading'>Lorem ipsum dolor sit amet consectetur. Sollicitudin integer faucibus vitae volutpat vel sed vel. Elit aliquam massa cras aliquam senectus pretium tempor nullam. Lorem ipsum dolor sit amet consectetur. Sollicitudin integer faucibus vitae volutpat vel sed vel. Elit aliquam massa cras aliquam senectus pretium tempor nullam.</div>
          <div className='hero2ndpart_left_rating'><div  className='hero2ndpart_leftrating'><img src={config.reviewstarimg1} alt="" />4.9 / 5 rating</div><div  className='hero2ndpart_leftrating'><img src={config.reviewstarimg2} alt="" />4.8 / 5 rating</div> </div>
      </div>
      <div  className='hero2ndpart_right'>
        <div className='hero2ndpart_right_container'><img src={config.hero2ndpartimg1} alt="" className='hero2ndpartimg1' /><div className='hero2ndpart_righttxt'><div className='hero2ndpart_righttxt_heading'>Technology & Innovation</div><div className='hero2ndpart_righttxt_subheading'>Lorem ipsum dolor sit amet consectetur. Sollicitudin integer faucibus vitae volutpat vel sed</div></div></div>
        <div className='hero2ndpart_right_container'><img src={config.hero2ndpartimg2} alt="" className='hero2ndpartimg1' /><div className='hero2ndpart_righttxt'><div className='hero2ndpart_righttxt_heading'>Health & Psychology</div><div className='hero2ndpart_righttxt_subheading'>Lorem ipsum dolor sit amet consectetur. Sollicitudin integer faucibus vitae volutpat vel sed</div></div></div>
        <div className='hero2ndpart_right_container'><img src={config.hero2ndpartimg3} alt="" className='hero2ndpartimg1' /><div className='hero2ndpart_righttxt'><div className='hero2ndpart_righttxt_heading'>Environmental Science & Policy</div><div className='hero2ndpart_righttxt_subheading'>Lorem ipsum dolor sit amet consectetur. Sollicitudin integer faucibus vitae volutpat vel sed</div></div></div>
      </div>
    </div>
 */}

    
     <div className='steps_box'>
       
       <div className='steps_heading' id='HowItWorks'><h3 className='subscription_title'>How it Work </h3><div className='steps_subtitle'> Writing academic papers has never been this easy! <br /> Follow these three simple steps to generate <br /> well-structured,  research-backed, and plagiarism-free content in minutes.</div> </div>
       
       <div className='all_steps'>
        <div className='steps_1'><div className='step_number'>01</div> <div className='steps_text'><div className='steps_text_title'>Enter Your Topic & Requirements ✨ </div><p className='steps_text_detail'>Provide your essay topic, research question, or assignment prompt. Customize details like: <br />Academic level (High School, College, PhD) <br />Word count & formatting style (APA, MLA, Chicago, Harvard) <br /> Research depth & citation preferences</p></div></div>
       <div className='steps_2'><div className='step_number'>02</div> <div className='steps_text'><div className='steps_text_title'>AI Generates a Structured Draft</div><p className='steps_text_detail'>Our AI analyzes your topic and creates a coherent, well-researched document, including: <br />Introduction – Engaging thesis statement <br />Body Sections – Well-structured arguments with evidence <br /> Citations & References – Properly formatted sources <br />Conclusion – Strong summary & final thoughts </p></div></div>
       <div className='steps_3'><div className='step_number'>03</div>  <div className='steps_text'><div className='steps_text_title'>Review, Edit & Download</div><p className='steps_text_detail'>Refine your document with built-in tools for: <br />Grammar & clarity enhancement <br />Plagiarism check for originality <br /> Customization & formatting tweaks</p></div></div>
        <div className='steps_4'><div className='step_number'>04</div>  <div className='steps_text'><div className='steps_text_title'>Edit & Share</div><p className='steps_text_detail'> Final Step? Submit with Confidence! <br />Start Writing Smarter Today! </p></div></div>
       </div>
     </div>

     <div className='subscription' id='Pricing'>
      <div className='subscription_title'>Choose Plan</div>
      <h3 className='steps_subtitle'>Choose plan that works best for you, feel free to contact us</h3>
      <h4 className='subscription_subtitle'>(No Credit Card required)</h4>
     {/*  <div className='plans_wrapper'>

        <div className='plan-1'><div><div className='price_details'><div className='price1'>$19</div><p className='price_interval1' > /month</p></div> <div className='discription1'><div className='price1'>Starter</div><div className='discription_subtitle1'>Unleash the power of automation</div></div><div className='points_list_full'> <div className='ponits_list1' ><img src={config.check_point} alt="" className="colored_check1"/><img src={config.check_point_white} alt=""  className="white_check1"/> Unlimited Text to Video Generator</div><div className='ponits_list1'><img src={config.check_point} alt="" className="colored_check1"/><img src={config.check_point_white} alt=""  className="white_check1"/>Unlimited HD Exports</div><div className='ponits_list1'><img src={config.check_point} alt="" className="colored_check1"/><img src={config.check_point_white} alt=""  className="white_check1"/> No Watermarks</div> <div className='ponits_list1'><img src={config.check_point} alt="" className="colored_check1"/><img src={config.check_point_white} alt=""  className="white_check1"/> Ads Free</div></div> </div><button className='choose_plan1'>Choose plan</button> </div>
        <div className='plan-2'><div><div className='price_details'><div className='price2'>$54</div><p className='price_interval2'> /month</p></div> <div className='discription2'><div className='price2'>Professional</div><div className='discription_subtitle2' >Advanced tools to take your work to the next level.</div></div><div className='points_list_full'> <div className='ponits_list2'><img src={config.check_point} alt="" className="colored_check2"/><img src={config.check_point_white} alt=""  className="white_check2"/>Unlimited Text to Video Generator</div><div className='ponits_list2'><img src={config.check_point} alt="" className="colored_check2"/><img src={config.check_point_white} alt=""  className="white_check2"/>Unlimited HD Exports</div><div className='ponits_list2'><img src={config.check_point} alt="" className="colored_check2"/><img src={config.check_point_white} alt="" className="white_check2"/>No Watermarks</div><div className='ponits_list2'><img src={config.check_point} alt="" className="colored_check2"/><img src={config.check_point_white} alt="" className="white_check2"/>Ads Free</div><div className='ponits_list2'><img src={config.check_point} alt="" className="colored_check2"/><img src={config.check_point_white} alt="" className="white_check2"/>Unlimited BG Music</div><div className='ponits_list2'><img src={config.check_point} alt="" className="colored_check2"/><img src={config.check_point_white} alt="" className="white_check2"/>Unlimited Stock Footages & Videos</div></div></div> <button className='choose_plan2'>Choose plan</button> </div>
        <div className='plan-3'><div><div className='MOSTPOPULAR'>MOST POPULAR</div></div><div className='price_details'><div className='price3'>$89</div><p className='price_interva3'>/month</p></div> <div className='discription'><div className='price3'>Company</div><div className='discription_subtitle3'>Automation plus enterprise-grade features</div></div><div className='points_list_full'> <div className='ponits_list3'><img src={config.check_point3} alt="" />multi-step Zaps</div><div className='ponits_list3'><img src={config.check_point3} alt="" />multi-step Zaps</div> <div className='ponits_list3'><img src={config.check_point3} alt="" />multi-step Zaps</div> </div> <button className='choose_plan'>Choose plan</button> </div> 
       <div className='plan-3'>{/*<div> <div className='MOSTPOPULAR'>MOST POPULAR</div> </div><div><div className='price_details'><div className='price3'>$84</div><p className='price_interval3'> /month</p></div> <div className='discription3'><div className='price3'>Advnaced</div><div className='discription_subtitle3' >Advanced tools to take your work to the next level.</div></div><div className='points_list_full'> <div className='ponits_list3'><img src={config.check_point} alt="" className="colored_check3"/><img src={config.check_point_white} alt=""  className="white_check3"/>Unlimited Text to Video Generator</div><div className='ponits_list3'><img src={config.check_point} alt="" className="colored_check3"/><img src={config.check_point_white} alt=""  className="white_check3"/>Unlimited HD Exports</div><div className='ponits_list3'><img src={config.check_point} alt="" className="colored_check3"/><img src={config.check_point_white} alt="" className="white_check3"/>No Watermarks</div><div className='ponits_list3'><img src={config.check_point} alt="" className="colored_check3"/><img src={config.check_point_white} alt="" className="white_check3"/>AdsFree</div><div className='ponits_list3'><img src={config.check_point} alt="" className="colored_check3"/><img src={config.check_point_white} alt="" className="white_check3"/>Unlimited Stock Footages & Videos</div><div className='ponits_list3'><img src={config.check_point} alt="" className="colored_check3"/><img src={config.check_point_white} alt="" className="white_check3"/>Unlimited BG Music</div><div className='ponits_list3'><img src={config.check_point} alt="" className="colored_check3"/><img src={config.check_point_white} alt="" className="white_check3"/>HD Resolution</div> </div> </div> <button className='choose_plan3'>Choose plan</button> </div>
      </div>*/}



      <div className='plans_wrapper'>
  {plans.map((plan, index) => (
    <div className={`plan-${index + 1}`} key={plan.id}>
      <div>
         <div className={`discription${index + 1}`}>
          <div className={`price${index + 1}`}>{plan.name}</div>
          {/* Static description subtitles */}
          {index === 0 && (
            <div className={`discription_subtitle1`}>
              Unleash the power of automation
            </div>
          )}
          {index === 1 && (
            <div className={`discription_subtitle2`}>
              Advanced tools to take your work to the next level.
            </div>
          )}
          {index === 2 && (
            <div className={`discription_subtitle3`}>
              Automation plus enterprise-grade features
            </div>
          )}
       
        </div>
        <div className='price_details'>
          <div className={`price${index + 1}`}>${plan.amount / 100}</div> {/* Assuming 'price' is in cents */}
          <p className={`price_interval${index + 1}`}> /{plan.name === 'Weekly' ? 'Weekly' : plan.name === 'Half Yearly' ? 'Half Yearly' : 'Yearly'}</p>
        </div>
       

        {/* Points List - Conditionally Render Based on Plan */}
        <div className='points_list_full'>
          {/* Common Points for All Plans */}
          <div className={`ponits_list${index + 1}`}>
            <img src={config.check_point} alt="" className={`colored_check${index + 1}`}   width="20px"
                  height="20px"/>
           
            AI Advanced Academic Writing Tool
          </div>
          <div className={`ponits_list${index + 1}`}>
            <img src={config.check_point} alt="" className={`colored_check${index + 1}`}  width="20px"
                  height="20px" />
            
            Unlimited Access to Academic Writing & Research
          </div>
          <div className={`ponits_list${index + 1}`}>
            <img src={config.check_point} alt="" className={`colored_check${index + 1}`}  width="20px"
                  height="20px" />
          
            Extensive Research Database
          </div>
          <div className={`ponits_list${index + 1}`}>
            <img src={config.check_point} alt="" className={`colored_check${index + 1}` }   width="20px"
                  height="20px"/>
           
            Ads Free
          </div>
          
         
        </div>
      </div>
      <button className={`choose_plan${index + 1}`} onClick={() => handleSubscribe(plan.id)}>Choose plan</button>
    </div>
  ))}
</div>




     </div>

     <div className="faq-container_hero" id='FAQ'>
      <h1 className="faq-title_hero">Frequently asked questions</h1>
      <div className="faq-items_hero">
        {config.FAQs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item_hero ${activeIndex === index ? 'active' : ''}`}
          >
            <div
              className={`faq-question_hero ${activeIndex === index ? 'open' : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              <span className="plus-icon_hero">+</span>
              <span className="faq-question-text_hero">{faq.question}</span>
            </div>
            <div className={`faq-answer_hero`}>
              <p>{faq.answerFAQ}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    <div  className='footer'>
      <div className='footer_top' id='Footer'>
        <div className='form_footer'>
          <div className='footer_headings'>aiacademicswriter.com</div>
         {/*  <p className='footer_subheadings'>Quickly generate faceless videos with customizable prompts, adjust aspect ratios, select music, choose fonts, and set the perfect duration. Create professional-quality content with ease!</p> */}
          <div><img src="" alt="" /><img src="" alt="" /><img src="" alt="" /></div>
        </div>
        <div className='product_footer'>
          <div className='footer_headings'>PRODUCT</div>
          <p className='footer_subheadings' onClick={() => scrollToSection('HowItWorks')} >How it works</p>
          <p className='footer_subheadings' onClick={() => scrollToSection('Features')} >Features</p>
          <p className='footer_subheadings' onClick={() => scrollToSection('Pricing')} >Pricing</p>
        </div>
        <div className='product_footer'>
          <div className='footer_headings'>Support</div>
          <p className='footer_subheadings' onClick={() => scrollToSection('FAQ')} >FAQ</p>
          {/* <Link to='/blog' style={{ textDecoration: 'none', color: 'inherit' }}><p className='footer_subheadings'  >Blog</p></Link> */}
          <Link to='/privacy' style={{ textDecoration: 'none', color: 'inherit' }}><p className='footer_subheadings'  >Privacy Policy</p></Link>
          <Link to='/refundpolicy' style={{ textDecoration: 'none', color: 'inherit' }}><p className='footer_subheadings'  >Refund policy</p></Link>
        </div>
        <div className='contact_footer'>
          <div className='footer_headings'>CONTACT</div>
          <p className='footer_subheadings'>speedapps1010@gmail.com</p>
          <p className='footer_subheadings'>8176851179</p>
          <Link to='https://generator.video/' style={{ textDecoration: 'none', color: 'inherit' }}><p className='footer_subheadings'  >Text to Video Generator</p></Link>
          <p className='footer_subheadings'></p>
        </div>

      </div>
      <div className='footer_terms'><Link to='/terms&condition' style={{ textDecoration: 'none', color: 'inherit' }}><p>Terms and conditions</p></Link><p>©{currentYear} - Form | All rights reserved</p></div>
    </div>
    </div>
  );
}

export default LandingPage;
