import React, { useEffect }  from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './library/Main';
import LandingPage from './library/components/landingpage';
import PrivacyPolicy from './library/components/PrivacyPolicy';
import TermsAndCondition from './library/components/TermsAndCondition';
import Refund from './library/components/Refundpolicy';
import Blogs from './library/components/Blogs';
import Blogpage from './library/components/Blogpage';
import Create from './library/components/createacademic';
import History from './library/components/History';
import HistoryView from './library/components/HistoryPreview';
import Buypage from './library/components/Buypremium';
import Result from './library/components/Generatepage';
import ProtectedRoute from './library/components/ProtectedRoute';
import NotFoundPage from './library/components/NotFoundPage';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  useEffect(() => {
    if (window.location.pathname === '/about' || window.location.pathname === '/login') {
      window.location.href = "*"; // Redirect to the 404 page
    }
  }, []);
  return (
    <>
    
      <ToastContainer />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms&condition" element={<TermsAndCondition />} />
        <Route path="/refundpolicy" element={<Refund />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:title" element={<Blogpage />} />

        {/* Protected Routes */}
        <Route path="/Dashboard" element={<ProtectedRoute element={<Main />} />} />
        <Route path="/create-academic" element={<ProtectedRoute element={<Create />} />} />
        <Route path="/history" element={<ProtectedRoute element={<History />} />} />
        <Route path="/result" element={<ProtectedRoute element={<Result />} />} />
        <Route path="/premium" element={<ProtectedRoute element={<Buypage />} />} />
        <Route path="/history-view" element={<ProtectedRoute element={<HistoryView />} />} />
        <Route path="*" element={<LandingPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;














