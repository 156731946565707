import React from 'react';
import './NotFoundPage.css'; // Import the CSS file

const NotFoundPage = () => {
  return (
    <div className='body_landing_page'>
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you're looking for doesn't exist.</p>
        {/* <a href="/" className="go-home-btn">Go Back Home</a> */}
      </div>
    </div>



    </div>
  );
};

export default NotFoundPage;
