import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  // ✅ Import useLocation
import copyicon from '../../assets/copy_btn.svg';
import shareicon from '../../assets/share_btn.svg';
import './HistoryPreview.css';
import { config } from '../../config';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';
import Leftpanel from './leftPanel';

const HistoryPreview = () => {
  const location = useLocation(); // ✅ Get data passed via navigate
  const navigate = useNavigate(); // ✅ For back navigation

  // Extract result from navigation state
  const result = location.state?.result || "No result available."; // Default text if no data received
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    document.documentElement.style.setProperty('--main-color', config.color);
  }, []);

  useEffect(() => {
    if (result) {
      setDisplayedText(result);
    }
  }, [result]);

  const handleCopy = () => {
    navigator.clipboard.writeText(displayedText);
    alert('Text copied to clipboard!');
    logEvent(analytics, 'academic_action', {
      action: 'copy_academic_content',
      button_name: 'copy_button',
      source: 'history_preview',
    });
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Generated Academics",
          text: displayedText,
        });
        logEvent(analytics, 'academic_action', {
          action: 'share_academic_content',
          button_name: 'share_button',
          page_name: 'history_preview_page',
          source: 'history_preview',
        });
      }
    } catch (error) {
      console.error('Error while sharing:', error);
      alert('Sharing failed. Please try again.');
    }
  };

  return (
    <>
    <Leftpanel />
    <div className='seperation-div'>
      
      <div className="generate-page-container">
        <div className="generate-topdiv">
          <div className='backbtntext'>
            <button className="back-button" onClick={() => navigate(-1)}> {/* ✅ Go back to previous page */}
              <img src={config.Backbutton} alt="Back" />
            </button>
            <span> Your Academic </span> 
          </div>
          <div className="button-container">
            <img src={copyicon} alt="Copy" onClick={handleCopy} />
            <img src={shareicon} alt="Share" onClick={handleShare} />
          </div>
        </div>
        <pre
          id="generate-document-content"
          
        >
          {displayedText}
        </pre>
      </div>
    </div>
    </>
  );
};

export default HistoryPreview;
