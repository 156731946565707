import React, { useState, useContext, createContext, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { config } from '../../config';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const setCookie = (name, value, days) => {
        const d = new Date();
        d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value}; expires=${d.toUTCString()}; path=/`;
    };

    const getCookie = (name) => {
        const nameEq = name + "=";
        const cookies = document.cookie.split(';');
        for (let c of cookies) {
            c = c.trim();
            if (c.startsWith(nameEq)) return c.substring(nameEq.length);
        }
        return "";
    };

    const removeCookie = (name) => {
        setCookie(name, "", -1);
    };

    useEffect(() => {
        const loadUserSession = async () => {
            const jwtToken = getCookie('auth_token');
            if (jwtToken) {
                try {
                    const response = await fetch(`${config.baseURL}/validate_token.php`, {
                        method: 'GET',
                        headers: { 'Authorization': `Bearer ${jwtToken}` },
                        credentials: 'include',
                    });

                    const data = await response.json();
                    if (data.user) {
                        setUser(data.user);
                    } else {
                        removeCookie('auth_token');
                        setUser(null);
                    }
                } catch (error) {
                    removeCookie('auth_token');
                    setUser(null);
                }
            }
        };
        loadUserSession();
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, getCookie, setCookie, removeCookie }}>
            {children}
        </AuthContext.Provider>
    );
};

const GoogleAuth = ({ onLoginSuccess, id, showDropdown = false }, ref) => {
    const { setUser, getCookie, setCookie, removeCookie } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    // Get user details from localStorage if logged in
    const userName = localStorage.getItem('user_name');
    const userPicture = localStorage.getItem('user_picture');

    // Google login handler
    const login = useGoogleLogin({
        onSuccess: (response) => {
            setIsLoading(false);
            handleLoginSuccess(response);
        },
        onError: () => {
            setIsLoading(false);
            toast.error('Login failed. Please try again.');
        },
        scope: 'openid profile email',
        responseType: 'token',
    });

    const handleButtonClick = () => {
        if (!isLoading) {
            setIsLoading(true);
            login();
        }
    };

    const handleLoginSuccess = async (response) => {
        if (response?.access_token) {
            await sendGoogleTokenToBackend(response.access_token);
        } else {
            toast.error('Google login failed. Please try again.');
        }
    };

    const sendGoogleTokenToBackend = async (googleToken) => {
        try {
            const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
                headers: { Authorization: `Bearer ${googleToken}` },
            });

            if (response.ok) {
                const data = await response.json();
                const { email, name, id, picture, verified_email } = data;

                const backendResponse = await fetch(`${config.baseURL}/login.php`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, name, social_id: id, profile_picture: picture, is_email_verified: verified_email }),
                });

                const backendData = await backendResponse.json();

                if (backendData.jwt) {
                    setCookie('auth_token', backendData.jwt, 7);
                    setUser({ email, name, picture, id: backendData.user_id });
                    
                    localStorage.setItem('user_name', name);
                    localStorage.setItem('user_picture', picture);

                    if (onLoginSuccess) {
                        onLoginSuccess({ email, name, picture, id: backendData.user_id });
                    }

                    logEvent(analytics, 'login', { method: 'Google', email });

                    // Redirect to Dashboard if specified
                    if (localStorage.getItem('navigateToDashboard') === 'true') {
                        navigate('/Dashboard');
                        localStorage.removeItem('navigateToDashboard');
                    }
                } else {
                    toast.error('Authentication failed. Please try again.');
                }
            } else {
                toast.error('Failed to fetch user profile from Google.');
            }
        } catch {
            toast.error('Failed to fetch user profile from Google.');
        }
    };

    const handleLogout = async () => {
        try {
            removeCookie('auth_token');
            await fetch(`${config.baseURL}/logout.php`, { method: 'POST', credentials: 'include' });
            logEvent(analytics, 'logout', { method: 'Google' });
            setUser(null);
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_picture');
            window.location.reload();
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    useImperativeHandle(ref, () => ({
        login: handleButtonClick,
    }));

    return (
        <div className="google-auth-container">
            {userName && userPicture ? (
                showDropdown ? (
                    <div className="dropdown-wrapper">
                        <button onClick={toggleDropdown} className="dropdown-btn" id={id}>
                            <img src={userPicture} alt={userName} className="user-picture" />
                            {userName}
                            <span className="dropdown-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4.293 5.293a1 1 0 0 1 1.414 0L8 7.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"/>
                                </svg>
                            </span>
                        </button>
                        {isDropdownOpen && (
                            <div className="dropdown-menu">
                                <ul>
                                    <li>
                                    <div onClick={handleLogout} className="logout-item">Logout</div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                ) : (
                    <button onClick={handleLogout} className="logout-btn" id={id}>Logout</button>
                )
            ) : (
                <button onClick={handleButtonClick} className="login-btn" id={id} disabled={isLoading}>
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
            )}
        </div>
    );
};

export default forwardRef(GoogleAuth);
