import React from 'react';
import writeLogo from '../../assets/write logo.svg'; 
import writeLogoActive from '../../assets/write logo - Copy.svg';
import historyLogo from '../../assets/history_logo.svg'; 
import historyLogoActive from '../../assets/history_logo - Copy.svg'; 
import './rightpanel.css';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';
import {config} from '../../config';
import Category from "./category";
import History from "./History";
import { useNavigate } from 'react-router-dom';
const RightPanel = ({ }) => {
    const navigate = useNavigate();

    const handleNavigate = (key) => {
        navigate('/create-academic', { state: { key } });
    };
       
 
    return (


        <div className='background_righPanel'>
          
          <div className='title_rightPanel'>Ai Academic Generator</div>

          <div className='Buy_button_card_mob' onClick={() => {navigate('/premium'); // Set active state
                                 }}><img src={config.crown} alt="" className='Buy_buttonimg_card_mob' /><div className='Buy_buttontxt_card_mob'><div className='Buy_button_card_mob_heading'>Buy Premium</div><div className='Buy_button_card_mob_subheading'>Unlock the full potential of AI Academic</div></div></div>

          <div className='buttons_container'>
            
                <div className='buttons_rightPanel'>
                    <button className='button_rightPanel' onClick={() => handleNavigate('Academic')}><img  className='buttonimg_rightPanel' src={config.Create_Academic} alt="" />Create Academic</button>
                   {/*  <button className='button_rightPanel'   onClick={() => handleNavigate('Research')}><img  className='buttonimg_rightPanel' src={config.AI_Research_Assistant} alt="" />AI Research Assistant</button> */}
                     <button className='button_rightPanel'   onClick={() => handleNavigate('Thesis')}><img  className='buttonimg_rightPanel' src={config.AI_Thesis_Statement_Generator} alt="" />AI Thesis Statement Generator</button>
                    <button className='button_rightPanel'  onClick={() => handleNavigate('Proposal')}><img  className='buttonimg_rightPanel' src={config.Research_Proposal_Generator} alt="" />Research Proposal Generator</button> 
                </div>
                <div className='Buy_card'>
                    Unlock Full Potential with Premium
                    <button>Buy Now <img src="" alt="" /></button>
                </div>
            </div>


            <div>
            <Category />
            <div className="history_right_panel">
            <div className='History_title_mob'>History <p onClick={() => {
                                    // handleSliderButtonClick(handleBlogClick);
                                     navigate('/history'); // Set active state
                                 }}>View all</p></div>
                                 <div className='history_to_scroll'>
            <History showHeader={false} /></div>
            </div>
            </div>

        </div>
        
    );
};

export default RightPanel;
