import React, { useState, useEffect} from 'react';
import './Main.css';
import TabButtons from './components/TabButtons';
import FAQ from './components/FAQ';
import History from './components/History';
import Buypremium from './components/buyone';
import PrivacyPolicy from './components/PrivacyPolicy';
import GoogleButton from './components/GoogleAuth';
import Generatepage from "./components/Generatepage";
import HistoryPreview from "./components/HistoryPreview";
import LeftPanel from "./components/leftPanel";
import RightPanel from "./components/rightPanel";


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from './components/Popup';
import ReactGA from 'react-ga4';
import { analytics } from './components/firebase'; 
import { logEvent } from 'firebase/analytics'; 
import { config } from '../config'; 
import { useRef } from 'react';
import TermsAndCondition from './components/TermsAndCondition';
import Refundpolicy from './components/Refundpolicy';
import { BrowserRouter as Router, Route, Switch , useLocation,useNavigate  } from 'react-router-dom';


function App() {
    const [activeTab, setActiveTab] = useState('write');    
    const [isOpen, setIsOpen] = useState(false);
    const [showPremium, setShowPremium] = useState(false);
    const [showHistoryPreview, setShowHistoryPreview] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [result, setResult] = useState('');
    const [showGenerationPage, setShowGenerationPage] = useState(false);
    const [Topic, setTopic] = useState('');
    const [selectType, setselectType] = useState('');
    const [SupportiveInput, setSupportiveInput] = useState('');
    const [SupportiveInput2, setSupportiveInput2] = useState('');
    const [language, setLanguage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({}); // Error state for validation
    const subscriptionStatus = JSON.parse(localStorage.getItem('subscriptionStatus'));
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setPopupVisible] = useState(false);
    const googleanalytics  = process.env.REACT_APP_GOOGLE_ANALYITCS_ID;
    const [activeButton, setActiveButton] = useState('home');
    const [activeSliderButton, setActiveSliderButton] = useState('home');
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const imageSrc = config.upgradetopro;
    const premiumButtonRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
     
    const toggleOverlay = () => setIsOpen((prev) => !prev);
    React.useEffect(() => {
       
        document.documentElement.style.setProperty('--main-color', config.color);
    }, [config.color]);
    
    useEffect(() => {
        ReactGA.initialize(googleanalytics); // Replace with your tracking ID
        ReactGA.send('pageview'); // Track initial page view
    }, []);
    useEffect(() => {
        if (analytics) {
          logEvent(analytics, 'app_opened_academic');
        } else {
          
        }
      }, []);

    
   

    


    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleHomeClick = () => {
        navigate('/');
        setShowPremium(false);
        setIsButtonVisible(true);
        setShowPrivacyPolicy(false);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setselectType('');
        setSupportiveInput('');
        setSupportiveInput2('');
        setLanguage('');
        setActiveTab('write'); 
        logEvent(analytics, 'Home_btn_academic', {
            button_name: 'home_button',
        });// Show main page content
    };
    
    const handleTermsClick = () => {
        navigate('/terms&condition');
          setShowPremium(false);
          setIsButtonVisible(false);
          setShowPrivacyPolicy(true);
          setShowGenerationPage(false);
          setShowHistoryPreview(false);
          setTopic('');
          
          setSupportiveInput('');
          setLanguage('');
          logEvent(analytics, 'terms&condition_btn_legal-Doc', {
              button_name: 'terms&condition_page',
              page: '__LegalDoc_home',
              action: 'Opens Privacy page',
          }); 
      };
      const handleRefundPolicyClick = () => {
        navigate('/refundpolicy');
          setShowPremium(false);
          setIsButtonVisible(false);
          setShowPrivacyPolicy(true);
          setShowGenerationPage(false);
          setShowHistoryPreview(false);
          setTopic('');
          
          setSupportiveInput('');
          setLanguage('');
          logEvent(analytics, 'Refund_btn_FacelessVideo', {
              button_name: 'Refund_page',
              page: '__FacelessVideo_home',
              action: 'Opens Privacy page',
          });
      };

    const handleBuyPremiumClick = () => {
        setShowPremium(true);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(false);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setselectType('');
        setSupportiveInput('');
        setSupportiveInput2('');
        setLanguage('');
        logEvent(analytics, '3_Days_btn_clicked_academic', {
            button_name: 'Buy_premium',
        });
    };

    const handlePrivacyPolicyClick = () => {
        navigate('/privacy');
        setShowPremium(false);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(true);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setselectType('');
        setSupportiveInput('');
        setSupportiveInput2('');
        setLanguage('');
        logEvent(analytics, 'Privacy_btn_academic', {
            button_name: 'Privacy_page',
        });
    };
    
    const handleSliderButtonClick = (callback) => {
        callback();
        logEvent(analytics, 'Slider_academic', {
            action: 'Slider_opended_academic',
            button_name: 'generate_email_button',
            page_name: 'email_writer_page',
            
            source: 'email_writer',
            timestamp: new Date().toISOString(),
          }); // Call the passed callback function
    };

    const handleBackToMain = () => {
        setShowPrivacyPolicy(false); 
        setShowGenerationPage(false);
        setShowPremium(false);
        setShowHistoryPreview(false);
        setIsButtonVisible(true);// Hide the privacy policy
    };
    const getCookie = (name) => {
        const nameEq = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
        }
        return "";
    };
    
    useEffect(() => {
        handleRedirect();
    }, []);
    
    const handleDocumentSelect = (generatedResult) => {
        setResult(generatedResult);
        setShowHistoryPreview(true);
    };
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    
        
    const closePopup = () => {
        setPopupVisible(false);
      };

      const handleRedirect = async () => {
        // Step 1: Extract subscription details from the URL (query parameters)
        const urlParams = new URLSearchParams(window.location.search);
        const subscriptionId = urlParams.get('subscription_id');
        const status = urlParams.get('status');
    
        // If subscription_id or status are not found in the URL, handle accordingly
        if (!subscriptionId || !status) {
            console.error('Missing subscription details in URL.');
            return;
        }
    
        // Step 2: Send the subscription details to the backend
        try {
            const jwtToken = getCookie('auth_token'); // Get JWT token from cookies (you should implement `getCookie`)
    
            if (!jwtToken) {
                console.error('User is not authenticated. No JWT token found.');
                return;
            }
    
            // Send subscription data to backend
            const response = await fetch(`${config.baseURL}/save_dodo_subscription.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    subscription_id: subscriptionId,
                    status: status,
                }),
            });
    
            const result = await response.json();
    
            // Step 3: Handle response from the backend
            if (response.status === 200 && result.success) {
                
    
                // Step 4: Store subscription status and plan ID in localStorage
                localStorage.setItem('subscriptionStatus', JSON.stringify(result.subscription_status.toUpperCase()));

                localStorage.setItem('planId', JSON.stringify(result.plan_id)); // Save plan_id to localStorage
                // Optionally, show a success message to the user
                toast('You have successfully subscribed to the plan! Enjoy your benefits!', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
              });
  
              // Step 5: Clear URL parameters
              const currentUrl = window.location.href;
              const newUrl = currentUrl.split('?')[0]; // Get the base URL without query params
              window.history.replaceState({}, '', newUrl); 
            } else {
                console.error('Error saving subscription:', result);
                
            }
        } catch (error) {
            console.error('Error during subscription save:', error);
          
        }
    };



      
    
    
  

      
    
    return (
       <>
        <div className='header_mobile'>
                <img
                    src={config.hamburger}
                    alt="Menu"
                    className="hamburger"
                    onClick={toggleOverlay}
                />
            Ai Academic Generator
           {/*  <div className='history_btn_header' onClick={() => {
                                    // handleSliderButtonClick(handleBlogClick);
                                     navigate('/history'); // Set active state
                                 }}><img src={config.historycolor} alt="history" className='slider-Homeimg' /> History</div> */}
            </div> 
              {/*   <div className='login_crown_container'>
                    <img src={config.crown} alt="Crown" onClick={handleBuyPremiumClick} />
                
                    {user ? (
                                <button id="logout_header" onClick={handleLogout}>Logout</button>
                            ) : (
                                <GoogleButton id="logout_header" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                            )}                           
                </div>*/}
            

            {isOpen && (
                <div className="overlay" onClick={toggleOverlay}>
                    <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                        <div className='topslider'>
                            <div className="close-icon" onClick={toggleOverlay}>&#10005;</div>
                            <img src={config.frame} alt="Frame" className='frame' />
                          
                        </div>
                        <div className="button-container-slider">
                        <button className='buy-premium-slider'  onClick={() => {                              // handleSliderButtonClick(handleBlogClick);
                                     navigate('/premium'); // Set active state
                                 }}>
                                <img src={config.crown} alt="Crown" className='slidercrown'  /> 3 Days Free Trial
                            </button>
                            <div className='buttonscrollonslider'>
                                <button className={`Home_btn_slider ${activeSliderButton === 'home' ? 'active' : ''}`} 
                                onClick={() => {
                                    handleSliderButtonClick(handleHomeClick);
                                    setActiveSliderButton('home'); // Set active state
                                }}>
                                    <span><img src={config.home} alt="Home" className='slider-Homeimg' /></span> Home
                                </button>
                                {/* <button className={`Home_btn_slider ${activeSliderButton === 'history' ? 'active' : ''}`} 
                                 onClick={() => {
                                    // handleSliderButtonClick(handleBlogClick);
                                     navigate('/history'); // Set active state
                                 }}>
                                    <span><img src={config.historycolor} alt="history" className='slider-Homeimg' /></span> History
                                </button> */}
                                 <button className={`Home_btn_slider ${activeSliderButton === 'Blog' ? 'active' : ''}`}
                                 onClick={() => {
                                   // handleSliderButtonClick(handleBlogClick);
                                    navigate('/blog'); // Set active state
                                }}>
                                    <span><img src={config.blogscolor} alt="Blog" className='slider-Homeimg' /></span> Blog
                                </button> 
                                <button className={`Home_btn_slider ${activeSliderButton === 'privacy' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handlePrivacyPolicyClick);
                                    setActiveSliderButton('privacy'); // Set active state
                                }}>
                                    <span><img src={config.privacycolor} alt="Privacy" className='slider-Homeimg' /></span> Privacy Policy
                                </button>
                                <button className={`Home_btn_slider ${activeSliderButton === 'Refund' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handleRefundPolicyClick);
                                    setActiveSliderButton('Refund'); // Set active state
                                }}>
                                    <span><img src={config.refundcolor} alt="Refund" className='slider-Homeimg' /></span> Refund Policy
                                </button>
                                <button className={`Home_btn_slider ${activeSliderButton === 'Terms' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handleTermsClick);
                                    setActiveSliderButton('Terms'); // Set active state
                                }}>
                                    <span><img src={config.termscolor} alt="Terms" className='slider-Homeimg' /></span> Terms & Condition
                                </button>
                            </div>
                           
                                <GoogleButton id="login_slider"  />
                           
                        </div>
                    </div>
                </div>
            )}
        <div className="App">
    <LeftPanel  className="LeftPanel"/>
    <RightPanel className="RightPanel"/>
    
            
            <ToastContainer />
           
{/* 
            <div id='container'>
                <div className="left_panel">
                    <img src={config.logo} alt="Logo" className='logo_left_panel' />
                    <h1 className="left_title">{config.title}</h1>
                    {/*  <img src={config.buyP} alt="Buy Premium" className='buy-btn-img-left-panel' onClick={handleBuyPremiumClick} /> 
                    <button className='buy-btn-img-left-panel' onClick={handleBuyPremiumClick} ><img src={config.crown} alt="Crown"  className='panelcrown' />3 Days Free Trial </button>


                     <div className='btn-scroll'> 
                        <button className={`scroll_btn ${activeButton === 'home' ? 'active' : ''}`} onClick={() => {
            handleHomeClick();
            setActiveButton('home');
        }}>
                        <span><img src={config.homelp} alt="Home" className='scroll-img' /></span> Home
                    </button>
                    <button className={`scroll_btn ${activeButton === 'privacy' ? 'active' : ''}`} onClick={() => {
            handlePrivacyPolicyClick();
            setActiveButton('privacy');
        }}>
                        <span><img src={config.privacy} alt="Privacy" className='scroll-img' /></span> Privacy Policy
                                                                               </button>
                                                                               <button className={`scroll_btn  ${activeSliderButton === 'Refund' ? 'active' : ''}`}
                                                                                onClick={() => {
                                                                                   handleSliderButtonClick(handleRefundPolicyClick);
                                                                                   setActiveSliderButton('Refund'); // Set active state
                                                                               }}>
                                                                                   <span><img src={config.privacy} alt="Refund" className='scroll-img' /></span> Refund Policy
                                                                               </button>
                                                                               <button className={`scroll_btn  ${activeSliderButton === 'Terms' ? 'active' : ''}`}
                                                                                onClick={() => {
                                                                                   handleSliderButtonClick(handleTermsClick);
                                                                                   setActiveSliderButton('Terms'); // Set active state
                                                                               }}>
                                                                                   <span><img src={config.privacy} alt="Terms" className='scroll-img' /></span> Terms & Condition
                                                                               </button></div>
                    {user ? (
                        <button id="login_left" onClick={handleLogout}>Logout</button>
                    ) : (
                        <GoogleButton onLoginSuccess={handleLoginSuccess} id="login_left" onLoginFailure={handleLoginFailure} />
                    )}
                </div>

                <div className={`main_panel ${isLoading ? 'loading' : showGenerationPage ? 'generation' : showPremium ? 'premium' : showPrivacyPolicy ? 'privacy' : 'main'}`}>
                {isPopupVisible && <Popup message={popupMessage} onClose={closePopup}  />}
                 {isLoading ? (
                          <>
                          <div className="full-screen-overlay"><div className="loader"></div></div>
                          
                      </>

)  : showGenerationPage ? (
    <Generatepage pathname="/Generatepage" result={result} setIsLoading={setIsLoading} onBack={handleBackToMain} />
  ) : showPremium ? (
    <Buypremium pathname="/Buypremium" onBack={() => {
      handleBackToMain();
      setActiveButton('home');
      setActiveSliderButton('home');
    }} />
  ) : showHistoryPreview ? (
    <HistoryPreview pathname="/HistoryPreview" result={result} onBack={handleBackToMain} />
  ) : location.pathname === '/privacy' ? (
    <PrivacyPolicy pathname="/privacy"  onBack={handleBackToMain} />
  ) : location.pathname === '/terms&condition' ? (
    <TermsAndCondition pathname="/terms&condition" onBack={handleBackToMain} />
  ) : location.pathname === '/refundpolicy' ? (
    <Refundpolicy pathname="/refundpolicy" onBack={handleBackToMain} />
  ) : (


                        
                 /*    ) : showGenerationPage ? (
                        <Generatepage result={result} onBack={handleBackToMain} />
                    ) : showPremium ? (
                        <Buypremium onBack={() => {
                            handleBackToMain();
                            setActiveButton('home');
                            setActiveSliderButton('home');
                        }} />
                    ) : showHistoryPreview ? (
                        <HistoryPreview result={result} onBack={handleBackToMain}/>
                    ) : showPrivacyPolicy ? (
                        <PrivacyPolicy onBack={() => {
                            handleBackToMain();
                            setActiveButton('home');
                            setActiveSliderButton('home');
                        }}/>
                    ) : ( 
                        <div className="main_page">
                            <div className='title_main_page'>
                                <h1 className='title_name'>{config.title}  {subscriptionStatus === 'ACTIVE' && (
            <img src={config.proicon} alt="" className='proicon' />
        )} </h1>
                                <p className='username_text'>
                                    {user ? (
                                        <span>Welcome, {user.name}.</span>
                                    ) : (
                                        <span>Please log in to view your history.</span>
                                    )}
                                </p>
                            </div>

                            <TabButtons activeTab={activeTab} onTabChange={setActiveTab}  analytics={analytics}  />

                            <div className="form">
                                {activeTab === 'write' && (
                                    <div>
                                        <div className="topic">{config.Topic}</div>
                                        <textarea
                                            className={`input_1 ${errors.Topic ? 'error' : ''}`} // Light red border for error
                                            placeholder={config.Topicplaceholder}
                                            value={Topic}
                                            onChange={(e) => setTopic(e.target.value)}
                                            maxLength={1000}
                                        ></textarea>
                                        {errors.Topic && <div className="error-message">{errors.Topic}</div>} {/* Error message */}

                                        
                                          {/*  <div>
                                                <div className="topic">{config.selectType}</div>                            
                                                <textarea  type="text"
                                                    className="input_1" // Light red border for error
                                                    placeholder={config.selectTypeplaceholder}
                                                    value={selectType}
                                                    onChange={(e) => setselectType(e.target.value)} > </textarea>                                                
                                            </div> 
                                            
                                            <div>
                                                <div className="topic">{config.selectType}</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="type"
                                                    id="language"
                                                    value={selectType}
                                                    onChange={(e) => setselectType(e.target.value)}
                                                >
                                                   <option value="No Type">No Type</option>
                                                    <option value="Argumentative">Argumentative</option>
                                                    <option value="Analytical">Analytical</option>
                                                    <option value="Expository">Expository</option>
                                                    <option value="Narrative">Narrative</option>
                                                    <option value="Persuasive">Persuasive</option>
                                                    <option value="Comparing/Contrast">Comparing/Contrast</option>
                                                    <option value="Classic">Classic</option>
                                                    <option value="Critique">Critique</option>
                                                    <option value="Memoir">Memoir</option>
                                                    <option value="Other">Other</option>

                                                </select>                                                                   
                                            
                                            </div>

                                            <div>
                                                <div className="topic">{config.SupportiveInput}</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="length"
                                                    id="language"
                                                    value={SupportiveInput}
                                                    onChange={(e) => setSupportiveInput(e.target.value)}
                                                >
                                                    <option value="No Citation">No Citation</option>
                                                    <option value="APA">APA</option>
                                                    <option value="MLA">MLA</option>
                                                    <option value="CMS or Chicago Style">CMS or Chicago Style</option>
                                                    <option value="IEEE">IEEE</option>
                                                    <option value="Harvard Style">Harvard Style</option>
                                                    <option value="AMA">AMA</option>
                                                    <option value="Vancouver">Vancouver</option>
                                                    <option value="Turabian">Turabian</option>
                                                    <option value="No Style">No Style</option>
                                                    <option value="Other">Other</option>
                                            
                                                </select>                                                                   
                                            
                                            </div>
                                            <div>
                                                <div className="topic">{config.SupportiveInput2}</div>                     
                        
                                                <select
                                                    className={`input_3 `}  // Light red border for error
                                                    name="length"
                                                    id="language"
                                                    value={SupportiveInput}
                                                    onChange={(e) => setSupportiveInput2(e.target.value)}
                                                >
                                                    <option value="Formal">Formal</option>
                                                    <option value="Informal">Informal</option>
                                                    <option value="Friendly">Friendly</option>
                                                    <option value="Funny">Funny</option>
                                                    <option value="Convincing">Convincing</option>
                                                    <option value="Luxury">Luxury</option>
                                                    <option value="Relaxed">Relaxed</option>
                                                    <option value="Professional">Professional</option>
                                                    <option value="Witty">Witty</option>
                                                    <option value="Adventurous">Adventurous</option>
                                                    <option value="Persuasive">Persuasive</option>
                                                    <option value="Bold">Bold</option>
                                                    <option value="Empathetic">Empathetic</option>
                                                    <option value="Other">Other</option>
                                            
                                                </select>                                                                   
                                            
                                            </div>


                                            <div>
                                                <div className="topic">Select Language</div>
                                                <select
                                                    className="input_3"  // Light red border for error
                                                    name="language"
                                                    id="language"
                                                    value={language}
                                                    onChange={(e) => setLanguage(e.target.value)}
                                                >

                                                    <option value="English">English</option>
                                                    <option value="Arabic">Arabic (RTL)</option>
                                                    <option value="Bulgarian">Bulgarian</option>
                                                    <option value="Chinese">Chinese (Simplified)</option>
                                                    <option value="French">French</option>
                                                    <option value="German">German</option>
                                                    <option value="Hebrew">Hebrew (RTL)</option>
                                                    <option value="Hindi">Hindi</option>
                                                    <option value="Indonesian">Indonesian</option>
                                                    <option value="Italian">Italian</option>
                                                    <option value="Japanese">Japanese</option>
                                                    <option value="Portuguese">Portuguese</option>
                                                    <option value="Punjabi">Punjabi</option>
                                                    <option value="Russian">Russian</option>
                                                    <option value="Spanish">Spanish</option>
                                                    <option value="Swahili">Swahili</option>
                                                    <option value="Tagalog">Tagalog</option>
                                                    <option value="Urdu">Urdu</option>
                                                    <option value="Other">Other</option>
                                                </select>                            
                                            </div>
                                           
                                       
                                        <img
                                            className='generate_btn'
                                            src={config.generate}
                                            alt="Generate"
                                            onClick={handleGenerate}
                                        />
                                    </div>
                                )}
                                {activeTab === 'history' && <History onDocumentSelect={handleDocumentSelect} />}
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>

            
            {!showGenerationPage && <FAQ />} */}


        </div>
        </>
    );
}

export default App;