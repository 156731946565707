import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import { GoogleOAuthProvider } from '@react-oauth/google'; // Google OAuth
import App from './App';
import { AuthProvider } from './library/components/GoogleAuth';
const root = ReactDOM.createRoot(document.getElementById('root'));

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
    <AuthProvider>
      <Router>  {/* Add Router here */}
        <App />
      </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
