import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import copyicon from '../../assets/copy_btn.svg';
import shareicon from '../../assets/share_btn.svg';
import './Generatepage.css';
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import { config } from '../../config';

const Generatepage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Retrieve the generated result from navigation state
    const result = location.state?.result || "No result available.";

    const [displayedText, setDisplayedText] = useState('');
    const [typing, setTyping] = useState('');

    useEffect(() => {
        if (result) {
            setDisplayedText(result);
            setTyping('');

            logEvent(analytics, 'result_displayed_academic', {
                result_length: result.length,
                generated_result: true
            });

            // Typing effect
            let index = -1;
            const typingInterval = setInterval(() => {
                if (index < result.length) {
                    setTyping(prev => prev + result.charAt(index));
                    index++;
                } else {
                    clearInterval(typingInterval);
                }
            }, 5);

            return () => clearInterval(typingInterval);
        }
    }, [result]);

    useEffect(() => {
        logEvent(analytics, 'component_view_academic', {
            component_name: 'Generatepage'
        });
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(displayedText);
        alert('Text copied to clipboard!');
        logEvent(analytics, 'text_copied', { text_length: displayedText.length });
    };

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: "Generated Academic",
                    text: displayedText,
                });
                logEvent(analytics, 'document_shared_academic', { text_length: displayedText.length });
            }
        } catch (error) {
            console.error('Error while sharing:', error);
            alert('Sharing failed. Please try again.');
        }
    };

    return (
        <div className="generate-page-container">
            <div className="generate-topdiv">
                <div className='backbtntext'>
                    <button className="back-button" onClick={() => navigate(-1)}>
                        <img src={config.Backbutton} alt="Back" />
                    </button>
                    <span>Your Academic</span>
                </div>
                <div className="button-container">
                    <img src={copyicon} alt="Copy" onClick={handleCopy} />
                    <img src={shareicon} alt="Share" onClick={handleShare} />
                </div>
            </div>
            <pre
                id="generate-document-content"
                style={{
                    whiteSpace: 'pre-wrap',
                    fontFamily: 'Noto Sans',
                    padding: '20px',
                    borderRadius: '5px'
                }}
            >
                {typing || displayedText}
            </pre>
        </div>
    );
};

export default Generatepage;
