import React, { useState, useEffect, useRef } from 'react';
import './RepetitionGroup.css'; // Import CSS
import copy from '../../assets/copy_history.svg';
import share from '../../assets/share_history.svg';
import historyimg from '../../assets/loading_history.svg'
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import { config } from '../../config';
import LeftPanel from "./leftPanel";
import { useNavigate } from 'react-router-dom';

const History = ({ onDocumentSelect,onBack, showHeader = true }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const isFetched = useRef(false);
     const navigate = useNavigate();
     useEffect(() => {
        logEvent(analytics, 'component_view', { component_name: 'History' });
    
        if (!isFetched.current) {  
            fetchHistory();
            isFetched.current = true; 
        }
    }, []);
    
    
    
    
    // Runs once on component mount
    const handleBackClick = () => {
        navigate(-1);
      };
    const fetchHistory = async () => {
        try {
            const response = await fetch(`${config.baseURL}/get_history.php`, {
                method: 'POST',  // Assuming backend expects POST
                credentials: 'include', // ✅ Sends cookies for JWT authentication
                headers: { 'Content-Type': 'application/json' }
            });

            const data = await response.json();

            if (data.success) {
                if (data.history && data.history.length > 0) {
                    setItems(data.history);
                } else {
                    setError('No History Yet !'); // Updated message for no history found
                }
            } else {
                setError(data.message || 'Failed to fetch history.');
                setItems([]);
            }
        } catch (error) {
            console.error('Error fetching history:', error);
            setError('An error occurred while fetching history.');
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        alert('Text copied to clipboard!');
        logEvent(analytics, 'text_copied', {
            text_length: text.length
        });
    };

    const handleShare = async (text) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: "Generated Academics",
                    text: text,
                });
                logEvent(analytics, 'document_shared', {
                    text_length: text.length
                });
            }
        } catch (error) {
          
            alert('Sharing failed. Please try again.');
        }
    };

    const navigateToResult = (navigate, item) => {
        if (!item?.ai_result) return;
    
        navigate('/history-view', { state: { result: item.ai_result } });
    
        logEvent(analytics, 'document_clicked', {
            document_id: item.id,
            document_preview: item.ai_result.substring(0, 50) // Preview of the text
        });
    };

    // Loading, error, and empty state handling
    if (loading) {
        return  (
            <div>
            <LeftPanel  />
            <div className='seperation-div'><div className='history_null'> <img src={historyimg} alt="History" />Loading...</div>;
            </div>
            </div>
            );
    }

    if (error) {
        return (
            <div>
                <LeftPanel  />
                <div className='seperation-div'>
                    <div className='history_null'> <img src={historyimg} alt="History" />{error}</div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className='leftpanel_history' >  <LeftPanel  />
            </div>

            <div className={showHeader ? 'seperation-div' : 'seperation-div no-padding'}>

            {showHeader && (
                    <div className='leftpanel_history'>
                        <div className='backbtntext'>
                            <button className="back-button" onClick={handleBackClick}>
                                <img src={config.Backbutton} alt="Back" />
                            </button>
                            <div> Your History </div>
                        </div>
                    </div>
                )}
           
                <div className='history_box'>
                {items.slice().reverse().map((item, index) => {
                    const wordLimit = item.ai_result.split(' ').length > 10 ? 6 : 2; // Show 3 words if more than 5, otherwise 2
                    const words = item.ai_result.split(' ');
                    const titleIndex = words.slice(0, 2).indexOf('Title:');
                    const startIndex = titleIndex !== -1 ? titleIndex + 1 : 0;
                    const firstWords = words.slice(startIndex, startIndex + wordLimit).join(' ')+ ' ...';
                    return (
                        <div className="repeating-group" key={index}    >
                            <span className="history_text" onClick={() => navigateToResult(navigate, item)}>{firstWords}</span> {/* Show only the specified number of words */}
                            <div className="icon-container">
                                <span className="copy_share_btn_history" >
                                    <img 
                                        src={copy} 
                                        alt="Copy" 
                                        onClick={(e) => { e.stopPropagation(); handleCopy(item.ai_result); }} 
                                    />Copy
                                </span>
                                <span className="copy_share_btn_history" >
                                    <img 
                                        src={share}     
                                        alt="Share" 
                                        onClick={(e) => { e.stopPropagation(); handleShare(item.ai_result); }} 
                                    />Share
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
            </div>
        </div>
    );
};

export default History;
