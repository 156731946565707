import React, { useState } from "react";
import { config } from "../../config";
import "./category.css";
import { useNavigate } from "react-router-dom";

const Category = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [viewAllPopupVisible, setViewAllPopupVisible] = useState(false);
  const [popupaskque, setpopupaskque] = useState(false);
  const [buttonTexts, setButtonTexts] = useState([]);
  const [clickedImage, setClickedImage] = useState(null);
  const [textLine1, setTextLine1] = useState("");
  const [textLine2, setTextLine2] = useState("");
  const [TopicValue, setTopicValue] = useState("");
  const navigate = useNavigate();

  // Function to navigate to the create-academic page with selected question
  const handleButtonClick = (text, category) => {
    navigate("/create-academic", { state: { text, category } });
  };

  

  // Function to handle image click and set the respective button texts
  const handleImageClick = (imageId) => {
    setClickedImage(imageId);
    setPopupVisible(true);

    const categoryData = {
      button1: {
        text: [
          "Artificial Intelligence and its societal impact",
          "Internet of Things (IoT) and privacy concerns",
          "Cybersecurity challenges in the era of cloud computing",
          "Role of blockchain beyond cryptocurrencies",
          "Impact of social media on mental health and public opinion",
        ],
        title: "Technology & Innovation",
      },
      button2: {
        text: [
          "Mental health awareness among students",
          "Cognitive-behavioral therapy (CBT) for anxiety management",
          "Impact of remote healthcare (telemedicine)",
        ],
        title: "Health & Psychology",
      },
      button3: {
        text: [
          "Impact of childhood trauma on adult mental health",
          "Psychology of addiction and new treatment approaches",
          "Effects of remote work on employee well-being and productivity",
          "The role of emotional intelligence in leadership success",
          "How social media affects interpersonal communication",
        ],
        title: "Psychology & Health Behavior",
      },
      button4: {
        text: [
          "Renewable energy technologies and climate change solutions",
          "Policies for sustainable urban development",
          "Impact of climate change on agriculture and food security",
          "Innovations in environmental conservation (e.g., reforestation technology)",
        ],
        title: "Environmental Science & Policy",
      },
      button5: {
        text: [
          "Influence of globalization on local cultures",
          "Cultural identity and its portrayal through film and literature",
          "Impact of social movements on societal norms (e.g., #MeToo, Black Lives Matter)",
          "Evolution of gender roles in modern media",
          "The role of museums in preserving indigenous histories",
        ],
        title: "Sociology & Culture",
      },
      button6: {
        text: [
          "Rise of the gig economy and its challenges",
          "Future of remote work and hybrid office models",
          "Entrepreneurship and small business strategies post-pandemic",
        ],
        title: "Business & Economics",
      },
      button7: {
        text: [
          "Immigration reform and its social impact",
          "Political polarization and its influence on governance",
          "Election security and digital voting systems",
          "The role of youth participation in modern politics",
        ],
        title: "Political Science & Social Issues",
      },
      button8: {
        text: [
          "Privacy laws and data protection in the digital age",
          "Impacts of police reform initiatives on public safety",
          "International human rights violations and global responses",
          "Legal challenges in regulating AI and automation",
          "The effectiveness of restorative justice programs",
        ],
        title: "Law & Human Rights",
      },
      button9: {
        text: [
          "Innovations in space exploration (e.g., SpaceX and Mars missions)",
          "Ocean pollution and strategies for marine life conservation",
          "CRISPR and ethical debates surrounding gene editing",
          "Role of urban farming in food sustainability",
          "Impact of microplastics on ecosystems and human health",
        ],
        title: "Science_and_environmental_studies",
      },
    };

    if (categoryData[imageId]) {
      setButtonTexts(categoryData[imageId].text);
      setTextLine1(categoryData[imageId].title);
      setTextLine2("Please select a question to continue.");
    }
  };


  const handleAskQueClick = () => {
    setpopupaskque(true);
    setViewAllPopupVisible(false);
    setPopupVisible(false);
}

  // Function to close popups
  const closePopup = () => setPopupVisible(false);
  const closeViewAllPopup = () => setViewAllPopupVisible(false);
  const closepopupaskque = () => setpopupaskque(false);
  return (
    <div>
      <div className="category_top">
        <div>Category</div>
        <div className="view_all" onClick={() => setViewAllPopupVisible(true)}>View All</div>
      </div>

      <div className="category_cards">
        {[...Array(9)].map((_, i) => (
          <img
            key={i}
            className="card_button"
            src={config[`card_button${i + 1}`]}
            alt=""
            onClick={() => handleImageClick(`button${i + 1}`)}
          />
        ))}
      </div>

      {/* Popup for Single Category */}
      {popupVisible && (
        <div className="popup_askabout">
          <div className="popup_content_askabout">
            <div className="popup_header_askabout">
              <span>Ask About</span>
              <button className="close_button_askabout" onClick={closePopup}>
                <img src={config.close_button_x} alt="" />
              </button>
            </div>
            <div className="popup_text_askabout">
              <div>{textLine1}</div>
              <p>{textLine2}</p>
            </div>
            <div className="popup_buttons_askabout">
              {buttonTexts.map((text, index) => (
                <button
                  key={index}
                  className="popup_button_askabout"
                  onClick={() => handleButtonClick(text, textLine1)}
                >
                  {text}
                </button>
              ))}
            </div>
            <div className="bottom_buttons_askabout">
              or <button className="askyourquestion" onClick={() => handleAskQueClick()}>Ask Your Own Question</button>
            </div>
          </div>
        </div>
      )}

      {/* Popup for View All */}
      {viewAllPopupVisible && (
        <div className="popup_viewall">
          <div className="popup_content_viewall">
            <div className="popup_header_viewall">
              <span>All Categories</span>
              <button className="close_button_viewall" onClick={closeViewAllPopup}>
                <img src={config.close_button_x} alt="" />
              </button>
            </div>
            <div className="popup_images_viewall">
              {[...Array(9)].map((_, i) => (
                <img
                  key={i}
                  className="popup_card_button"
                  src={config[`card_button${i + 1}`]}
                  alt=""
                  onClick={() => {
                    handleImageClick(`button${i + 1}`);
                    closeViewAllPopup();
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}

       {popupaskque && (
        <div className="popup_askabout">
          <div className="popup_content_askabout">
            <div className="popup_header_askabout">
              <span>Ask About</span>
              <button className="close_button_askabout" onClick={closepopupaskque}>
                <img src={config.close_button_x} alt="" />
              </button>
            </div>
            <div className="popup_text_askabout">
              <div>Askyour own question</div>             
            </div>
            
            <textarea className="Topic_askabout" id="" placeholder="Type your text here...." value={TopicValue}  name="Topic"
               
                onChange={(e) => setTopicValue(e.target.value)}
                maxLength={1000}>  </textarea>
            <div className="bottom_buttons_askabout">
            <button className="askyourquestion"  onClick={() => handleButtonClick(TopicValue)}>Submit</button>
            </div>
          </div>
        </div>
      )}
      
      
    </div>
  );
};

export default Category;
