import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { config } from '../../config';

const ProtectedRoute = ({ element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);  // null means loading

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await fetch(`${config.baseURL}/validate_token.php`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getCookie('auth_token')}`,
                    },
                    credentials: 'include',
                });

                if (response.ok) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        validateToken();
    }, []);

    if (isAuthenticated === null) {
        return <div></div>; // Show a loading indicator while checking auth
    }

    return isAuthenticated ? element : <Navigate to="/" />;
};

// Utility function to get cookies
const getCookie = (name) => {
    const nameEq = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
    }
    return "";
};

export default ProtectedRoute;
