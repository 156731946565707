import React from 'react';
import './FullScreenLoader.css'; // ✅ Import CSS for styling

const FullScreenLoader = () => {
    return (
        <div className="fullscreen-loader">
            <div className="loader"></div>
            <p>Generating... Please wait</p>
        </div>
    );
};

export default FullScreenLoader;
