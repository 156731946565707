import React from 'react';
import './PrivacyPolicy.css'; 
import {config} from '../../config';
import { useNavigate } from 'react-router-dom';
import LeftPanel from "./leftPanel";

const PrivacyPolicy = ({ onBack }) => {
const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  React.useEffect(() => {
       
    document.documentElement.style.setProperty('--main-color', config.color);
}, [config.color]);
  return (
    <>
    <LeftPanel/>
    <div className='seperation-div'>
      
    <div className="privacy-policy-container">
      <div className="privacytopdiv">
        <button className="back-button" onClick={handleBackClick}>
          <img src={config.Backbutton} alt="Back" />
          <span>Privacy Policy</span>
        </button>
      </div>

      <div id="policy-content">
      <p>{config.privacyTitle}</p>
      <p>Effective Date: {config.privacyEffectiveDate}</p>
      {config.privacyPoints.map((section, index) => (
        <div key={index}>
          <p>{section.heading}</p>
          {section.subsections ? (
            section.subsections.map((sub, subIndex) => (
              <div key={subIndex}>
                <p>{sub.heading}</p>
                {sub.content.map((text, textIndex) => (
                  <p key={textIndex}>{text}</p>
                ))}
              </div>
            ))
          ) : (
            <div>
              {section.content.map((text, textIndex) => (
                <p key={textIndex}>{text}</p>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
    </div>
    </div>
    </>
  );
};

export default PrivacyPolicy;
